import * as zod from 'zod';

import { DHConfiguration } from '@sb/integrations/implementations/dh/comon/types/DHConfiguration';

export const DHCGI100170Configuration = DHConfiguration.extend({
  kind: zod.literal('DHCGI100170'),
  name: zod.string().default('DH CGI 100/170'),
});

export type DHCGI100170Configuration = zod.infer<
  typeof DHCGI100170Configuration
>;

export const DefaultConfiguration = DHCGI100170Configuration.parse({
  kind: 'DHCGI100170',
  family: 'DHBase',
  // NOTE It is recommended that the gripper not be used for <40mm. However, need to use the actual minimum width so that the scaling to/from [0,1] works correctly. See here: https://en.dh-robotics.com/product/cg###
  // NOTE We did measurements in shop to get these values. Confirmed that these correspond to normalized 0 and 1. However, measurements with inches-based tape measure may have some error.
  minGripWidth: 0.032,
  maxGripWidth: 0.172,
});
