import type { Space } from '@sb/routine-runner';

import type { EnvironmentVariable } from './environment-variable';
import type { Step } from './step';
import type { User } from './user';

export namespace Routine {
  /** Only these fields are editable by users. */
  export interface EditableFields {
    currentEditorID: string | null;
    description: string;
    environmentVariables: Array<EnvironmentVariable>;
    isTemplate: boolean;
    name: string;
    space: Space.Item[];
    steps: Step.Summary[];
    stepConfigurations: Record<string, Step.Configuration>;
    /**
     * Each routine needs a 'mainLoop' step that can be assigned either
     * automatically or manually. When assigning it automatically, the
     * `mainLoopStepID` prop is null, and the main loop
     * is derived from the outermost loop step.
     */
    mainLoopStepID: string | null;
    motionPlanner: 'ROS1' | 'ROS2';
  }

  /** Fields converted to the format used in the frontend. */
  export interface ConvertedResponse
    extends Omit<EditableFields, 'steps' | 'stepConfigurations'> {
    configurationUpdatedAt: Date;
    createdAt: Date;
    createdBy: User.Summary;
    id: string;
    isDisabled: boolean;
    isNew: boolean;
    steps: Step.ConvertedSummary[];
    stepConfigurations: Record<string, Step.ConvertedConfiguration>;
    updatedAt: Date;
  }

  export type Summary = Pick<
    ConvertedResponse,
    | 'id'
    | 'isDisabled'
    | 'isNew'
    | 'isTemplate'
    | 'createdAt'
    | 'createdBy'
    | 'description'
    | 'name'
    | 'updatedAt'
    | 'motionPlanner'
  >;
  export const VALID_MOTION_PLANNERS: EditableFields['motionPlanner'][] = [
    'ROS1',
    'ROS2',
  ];
}
