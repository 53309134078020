// @ts-ignore
import type * as RCL from 'rclnodejs';

import { targetToROS2Tooltip } from '@sb/geometry';
import { info, namespace } from '@sb/log';
import type {
  JointArmTarget,
  ArmTarget,
  MotionPlanRequest,
  PoseArmTarget,
} from '@sb/motion-planning';
import {
  DEFAULT_BASE_ACCELERATION_SCALING,
  DEFAULT_BASE_VELOCITY_SCALING,
} from '@sb/motion-planning';
import { getRCL } from '@sb/ros/getRCL';
import type { SpeedProfile } from '@sb/routine-runner';

import { BaseAction } from './BaseAction';
import { JOINT_NAMES } from './constants';

const ns = namespace('TaskFromRobotGoalAction');

export type TaskFromRobotGoalActionRequest = {
  targets: MotionPlanRequest['targets'];
  speedProfile: SpeedProfile;
  startJointState: number[];
  ikSolverType?: 'Distance' | 'Speed';
  ignoredCollisionLinkPairs?: Array<[string, string]>;
};

export class TaskFromRobotGoalAction extends BaseAction<
  'standard_bots_msgs/action/TaskFromRobotGoal',
  RCL.standard_bots_msgs.action.TaskFromRobotGoal_Goal,
  RCL.standard_bots_msgs.action.TaskFromRobotGoal_Result
> {
  private request: TaskFromRobotGoalActionRequest;

  public constructor(request: TaskFromRobotGoalActionRequest) {
    super();
    this.request = request;
  }

  protected override getType(): RCL.TypeClass<keyof RCL.ActionsMap> {
    return 'standard_bots_msgs/action/TaskFromRobotGoal';
  }

  protected override getTopic() {
    return '/make_task_from_robot_goal';
  }

  protected override async createGoal() {
    const rcl = await getRCL();

    const TaskFromRobotGoal = rcl.require(
      'standard_bots_msgs/action/TaskFromRobotGoal',
    );

    const goalMessage = new TaskFromRobotGoal.Goal();

    info(ns`createGoal`, 'creating goal message');
    const { targets, ignoredCollisionLinkPairs } = this.request;

    info(ns`createGoal`, 'targets', targets);

    goalMessage.goals = targets.map((target) =>
      this.getGoalFromTarget(rcl, target),
    );

    goalMessage.ignored_collision_link_pairs =
      ignoredCollisionLinkPairs?.map(([body1, body2]) => {
        const collision = rcl.createMessageObject(
          'standard_bots_msgs/msg/Collision',
        );

        collision.body_1 = body1;
        collision.body_2 = body2;

        return collision;
      }) ?? [];

    goalMessage.connections = targets.map((target) =>
      this.createConnection(rcl, target),
    );

    goalMessage.start_joint_state = this.getStartJointState(rcl);

    goalMessage.ik_solver_type =
      this.request.ikSolverType === 'Speed'
        ? TaskFromRobotGoal.Goal.SOLVE_TYPE_SPEED
        : TaskFromRobotGoal.Goal.SOLVE_TYPE_DISTANCE;

    info(
      ns`createGoal`,
      `finished creating goal message: ${JSON.stringify(goalMessage.toPlainObject())}`,
    );

    return goalMessage;
  }

  private getStartJointState(rcl: typeof RCL) {
    const jointState = rcl.createMessageObject('sensor_msgs/msg/JointState');

    jointState.name = JOINT_NAMES;

    info(
      ns`getStartJointState`,
      `setting start positions to ${this.request.startJointState}`,
    );

    jointState.position = this.request.startJointState;

    return jointState;
  }

  private getGoalFromTarget(rcl: typeof RCL, target: ArmTarget) {
    if ('jointAngles' in target) {
      info(ns`getGoalFromTarget`, 'creating joint space target');

      return this.createJointStateGoal(rcl, target);
    }

    if ('pose' in target) {
      info(ns`getGoalFromTarget`, 'creating cartesian pose target');

      return this.createCartesianPoseGoal(rcl, target);
    }

    throw new Error('Unsupported target type');
  }

  private createJointStateGoal(rcl: typeof RCL, target: JointArmTarget) {
    info(ns`createGoal`, 'setting joint space goal');

    const jointState = rcl.createMessageObject('sensor_msgs/msg/JointState');

    info(ns`setJointConstraint`, `setting names to ${JOINT_NAMES}`);

    jointState.name = JOINT_NAMES;

    info(ns`setJointConstraint`, `setting positions to ${target.jointAngles}`);
    jointState.position = target.jointAngles;

    const RobotGoal = rcl.require('standard_bots_msgs/msg/RobotGoal');

    const robotGoal = rcl.createMessageObject(
      'standard_bots_msgs/msg/RobotGoal',
    );

    robotGoal.type = RobotGoal.TYPE_JOINT_STATE;
    robotGoal.goal_joint_state = jointState;

    return robotGoal;
  }

  private createCartesianPoseGoal(rcl: typeof RCL, target: PoseArmTarget) {
    info(ns`createCartesianPoseGoal`, 'setting cartesian pose goal');

    const pose = rcl.createMessageObject('geometry_msgs/msg/PoseStamped');

    const now = new rcl.Clock().now();

    pose.header.stamp.sec = now.secondsAndNanoseconds.seconds;
    pose.header.stamp.nanosec = now.secondsAndNanoseconds.nanoseconds;
    pose.header.frame_id = 'world';

    const tooltipPose = targetToROS2Tooltip(target.pose);

    pose.pose.position.x = tooltipPose.x;
    pose.pose.position.y = tooltipPose.y;
    pose.pose.position.z = tooltipPose.z;

    pose.pose.orientation.x = tooltipPose.i;
    pose.pose.orientation.y = tooltipPose.j;
    pose.pose.orientation.z = tooltipPose.k;
    pose.pose.orientation.w = tooltipPose.w;

    info(ns`createCartesianPoseGoal`, 'pose', pose.pose);

    const RobotGoal = rcl.require('standard_bots_msgs/msg/RobotGoal');

    const robotGoal = rcl.createMessageObject(
      'standard_bots_msgs/msg/RobotGoal',
    );

    robotGoal.type = RobotGoal.TYPE_POSE;
    robotGoal.goal_pose = pose;

    return robotGoal;
  }

  private createConnection(rcl: typeof RCL, target: ArmTarget) {
    const Connection = rcl.require('standard_bots_msgs/msg/Connection');

    const connection = rcl.createMessageObject(
      'standard_bots_msgs/msg/Connection',
    );

    if (target.motionKind === 'line') {
      info(
        ns`createGoal`,
        `setting connection to TYPE_PILZ_LIN ${Connection.TYPE_PILZ_LIN}`,
      );

      connection.type = Connection.TYPE_PILZ_LIN;
    } else if ('pose' in target) {
      info(
        ns`createGoal`,
        `setting connection to TYPE_OMPL ${Connection.TYPE_OMPL}`,
      );

      connection.type = Connection.TYPE_OMPL;
    } else {
      info(
        ns`createGoal`,
        `setting connection to TYPE_OMPL ${Connection.TYPE_OMPL}`,
      );

      connection.type = Connection.TYPE_OMPL;
    }

    connection.plan_options = this.createPlanOptions(rcl, target);

    return connection;
  }

  private createPlanOptions(rcl: typeof RCL, target: ArmTarget) {
    const planOptions = rcl.createMessageObject(
      'standard_bots_msgs/msg/PlanOptions',
    );

    info(ns`createPlanOptions`, 'speedprofile', this.request.speedProfile);

    planOptions.velocity_scaling =
      this.request.speedProfile.baseVelocityScaling ??
      DEFAULT_BASE_VELOCITY_SCALING;

    planOptions.acceleration_scaling =
      this.request.speedProfile.baseAccelerationScaling ??
      DEFAULT_BASE_ACCELERATION_SCALING;

    planOptions.max_acceleration_limit = [
      ...this.request.speedProfile.maxJointAccelerations,
    ];

    planOptions.max_velocity_limit = [
      ...this.request.speedProfile.maxJointSpeeds,
    ];

    planOptions.max_tooltip_velocity =
      this.request.speedProfile.maxTooltipSpeed;

    if (target.blendRadius && target.blendRadius > 0) {
      planOptions.blending_radius = target.blendRadius;
    }

    info(ns`createPlanOptions`, 'planOptions', planOptions);

    return planOptions;
  }
}
