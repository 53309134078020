/**
 * Routine specification constructed as a zod schema for static and
 * dynamic validation.
 */
import * as zod from 'zod';

import { RoutineStepConfiguration } from './Step';
import { Expression, Space } from './types';

// This is the Routine object for actually running the routine in botman.
// The steps have been converted to the RoutineStepConfiguration.
export const Routine = zod.object({
  id: zod.string(),
  name: zod.string(),
  steps: zod.array(RoutineStepConfiguration).nonempty(),
  mainLoop: zod.string().optional(),
  environmentVariables: zod // only local
    .array(
      zod.object({
        id: zod.string(),
        initialValue: Expression.optional(),
        name: zod.string(),
        isGlobal: zod.boolean().optional().default(false),
      }),
    )
    .optional(),
  space: zod.array(Space.Item).optional(),
  motionPlanner: zod
    .union([zod.literal('ROS1'), zod.literal('ROS2')])
    .optional(),
});

export type Routine = zod.infer<typeof Routine>;
