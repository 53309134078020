import * as zod from 'zod';

export enum ModbusFunctionCodeEnum {
  ReadCoils = 0x01,
  ReadDiscreteInputs = 0x02,
  ReadHoldingRegisters = 0x03,
  ReadInputRegisters = 0x04,
  WriteSingleRegister = 0x06,
  WriteSingleCoil = 0x05,
  WriteMultipleCoils = 0x0f,
  WriteMultipleRegisters = 0x10,
}

export const ModbusFunctionCode = zod.nativeEnum(ModbusFunctionCodeEnum);
