import * as zod from 'zod';

import { CustomEquipmentActionConfiguration } from '@sb/integrations/types/customEquipmentTypes';

export const IntegrationActionGroup = zod.object({
  name: zod.string(),
  actions: CustomEquipmentActionConfiguration.array().default([]),
});

export type IntegrationActionGroup = zod.infer<typeof IntegrationActionGroup>;
