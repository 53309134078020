import type { RobotIdentityData } from './types';
import { readJSONFileWithFallback, writeJSONFileWithDefaults } from './util';

const path = '/etc/standardbots/configuration/bot_identity.json';

const getFallback = () => {
  return process.env.NODE_ENV === 'development'
    ? ({
        robotId: 'dev',
        alias: 'dev',
        cloudflareTunnelId: undefined,
        applicationUrl: null,
        robotOperationMode: 'unknown',
      } as const)
    : undefined;
};

export const loadHostIdentity = async (): Promise<
  RobotIdentityData | undefined
> => {
  return readJSONFileWithFallback<RobotIdentityData | undefined>(
    path,
    getFallback(),
  );
};

export const updateRobotIdentity = async (data: Partial<RobotIdentityData>) => {
  return writeJSONFileWithDefaults<RobotIdentityData | undefined>(
    path,
    data,
    getFallback(),
  );
};
