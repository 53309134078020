import type { CustomEquipmentActionConfiguration } from '@sb/integrations/types/customEquipmentTypes';

import type { IntegrationActionKind } from '../types';

export function isIntegrationActionMatch(
  action: CustomEquipmentActionConfiguration,
  matchKind: IntegrationActionKind,
  matchName?: string,
) {
  return (
    action.kind === matchKind &&
    (matchKind !== 'custom' || action.customActionName === matchName)
  );
}
