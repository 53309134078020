import type { RoutineData, StepSummary } from '@sb/feathers-types';
import type { Routine } from '@sb/remote-control/types';

export const ROUTINE_DATA_SUMMARY_FIELDS = [
  'id',
  'isTemplate',
  'createdAt',
  'createdByID',
  'createdByName',
  'createdByPhoto',
  'description',
  'name',
  'steps',
  'updatedAt',
  'motionPlanner',
] as const;

const TWENTYFOUR_HOURS = 24 * 60 * 60 * 1000;

export function convertRoutineSummary(
  data: Pick<RoutineData, (typeof ROUTINE_DATA_SUMMARY_FIELDS)[number]>,
): Routine.Summary {
  const createdAt = new Date(data.createdAt);
  const updatedAt = new Date(data.updatedAt);

  const isNew = Date.now() - createdAt.valueOf() < TWENTYFOUR_HOURS;

  return {
    createdAt: new Date(data.createdAt),
    createdBy: {
      id: data.createdByID,
      isProfileSetup: true,
      name: data.createdByName,
      photo: data.createdByPhoto,
    },
    description: data.description,
    id: data.id,
    isDisabled: false,
    isNew,
    isTemplate: data.isTemplate ?? false,
    name: data.name,
    updatedAt,
    motionPlanner:
      data.motionPlanner as Routine.EditableFields['motionPlanner'],
  };
}

function countSteps(steps?: StepSummary[]) {
  let count = 0;

  steps?.forEach((step) => {
    count += 1 + countSteps(step.steps);
  });

  return count;
}

export function convertRoutineSummaryWithDefaultDescription(
  data: Pick<RoutineData, (typeof ROUTINE_DATA_SUMMARY_FIELDS)[number]>,
): Routine.Summary {
  const getDefaultDescription = () => {
    const count = countSteps(data.steps);

    if (count === 1) {
      return '1 step';
    }

    return `${count} steps`;
  };

  const summary = convertRoutineSummary(data);

  if (!summary.description) {
    summary.description = getDefaultDescription();
  }

  return summary;
}
