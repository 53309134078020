import type { CustomEquipmentActionConfiguration } from '@sb/integrations/types/customEquipmentTypes';

export function isIntegrationActionConfigured(
  action: CustomEquipmentActionConfiguration,
) {
  switch (action.actionOutputs.kind) {
    case 'controlBoxIO': {
      return (
        action.actionOutputs.outputs.length > 0 &&
        action.actionOutputs.outputs.every((o) => o.ioPort !== null)
      );
    }
    case 'modbusTCP': {
      return action.actionOutputs.operations.length > 0;
    }
    case 'haasNGC': {
      return action.actionOutputs.program !== '';
    }
    default:
      return false;
  }
}
