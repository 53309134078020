import * as zod from 'zod';

import { Step } from '@sb/remote-control/types';

import { convertMetersPerSecond } from '../../convertMetersPerSecond';
import { WeldMachineMode } from '../../types/WeldMachineMode';

export namespace WeldStepDatabase {
  export const name = 'Weld';
  export const description = 'Perform a weld';
  export const deviceKinds = [
    'WeldMachine',
    'MillerWeldMachine',
    'EsabWeldMachine',
  ] as const;
  export const isDecorator = true;
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const librarySort = '2';

  export const argumentKind = 'Weld';

  export const defaultVoltage = 15;

  export const defaultWireFeedSpeed =
    convertMetersPerSecond.fromInchesPerMinute(250);

  export const defaultTravelSpeed =
    convertMetersPerSecond.fromInchesPerMinute(15);

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    mode: WeldMachineMode.default('constantVoltage'),
    voltage: zod.number().default(defaultVoltage),
    arcStartTime: zod.number().default(0), // seconds
    craterFillTime: zod.number().default(0), // seconds
    wireFeedSpeed: zod.number().default(defaultWireFeedSpeed), // meters per second
    travelSpeed: zod.number().default(defaultTravelSpeed), // meters per second
    stickoutOffset: zod.number().default(0), // meters
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    return {
      ...stepData,
      stepKind: 'Weld',
      args,
    };
  };

  /**
   * Step description for the weld step is "Weld through X points"
   *
   * NOTE (MW,2024-12-05) Getting the number of points in the weld is somewhat compute-intensive. Could be misleading with loops, etc. A simpler solution would be to just do `return '  '`. Notice that we are returning an empty string _with_ whitespace. This prevents the step description from being autofilled.
   */
  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration,
    routine,
    includeStepName,
  }) => {
    if (stepConfiguration?.stepKind !== argumentKind) return null;
    if (routine == null) return null;

    const stepKindsToInclude = ['MoveArmTo']; // TODO Update once we add waypoints, arcs, etc.

    // Find the current step with all of its substeps
    const step = routine.steps.find((s) => s.id === stepConfiguration.id);
    if (step == null) return null;
    let nPoints = 0;

    // Work through all substeps (including those in loops, etc.)
    let stepsToExplore = [step];

    while (stepsToExplore.length > 0) {
      const currentStep = stepsToExplore.pop();

      if (currentStep == null) continue;

      if (stepKindsToInclude.includes(currentStep.stepKind)) {
        nPoints += 1;
      }

      if (currentStep.steps != null) {
        stepsToExplore = stepsToExplore.concat(currentStep.steps);
      }
    }

    const msg = [
      includeStepName ? 'Weld' : false,
      `through ${nPoints}`,
      `point${nPoints === 1 ? '' : 's'}`,
    ]
      .filter((s) => s != null && s !== false)
      .join(' ');

    return msg;
  };
}

WeldStepDatabase satisfies Step.StepKindInfo;
