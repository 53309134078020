// @ts-ignore
import type * as RCL from 'rclnodejs';

import { getRCL } from '@sb/ros/getRCL';

import { BaseService } from './BaseService';

export class RecoveryService extends BaseService<
  'standard_bots_msgs/srv/Recovery',
  RCL.standard_bots_msgs.srv.Recovery_Request,
  RCL.standard_bots_msgs.srv.Recovery_Response
> {
  public constructor() {
    super();
  }

  protected override getType(): RCL.TypeClass<keyof RCL.ServicesMap> {
    return 'standard_bots_msgs/srv/Recovery';
  }

  protected override getServiceName() {
    return 'get_recovery_trajectory';
  }

  protected override async createRequest() {
    const rcl = await getRCL();

    const Recovery = rcl.require('standard_bots_msgs/srv/Recovery');

    const request = new Recovery.Request();

    return request;
  }
}
