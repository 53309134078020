import { v4 as uuid } from 'uuid';

import type {
  IntegrationActionGroup,
  IntegrationActionControlBoxIOInterface,
} from '@sb/integrations/io/types';

import type { MachineStatusConfig } from './MachineStatus';

function createControlBoxIOInterface(): IntegrationActionControlBoxIOInterface {
  return {
    kind: 'controlBoxIO',
    outputs: [{ ioPort: null, isHigh: true, isPulse: true }],
    pulseSeconds: 0.5,
  };
}

export function createGenericCycle(): IntegrationActionGroup {
  return {
    name: 'Start cycle',
    actions: [
      {
        id: uuid(),
        kind: 'start',
        customActionName: '',
        actionOutputs: createControlBoxIOInterface(),
      },
    ],
  };
}

export function createGenericMachineStatus(): MachineStatusConfig {
  return {
    running: {
      kind: 'controlBoxIO',
      ioPort: null,
      isHigh: true,
    },
    faulted: null,
  };
}

export function createGenericAutodoor(): IntegrationActionGroup {
  return {
    name: 'Autodoor',
    actions: [
      {
        id: uuid(),
        kind: 'open',
        customActionName: '',
        actionOutputs: createControlBoxIOInterface(),
      },
      {
        id: uuid(),
        kind: 'close',
        customActionName: '',
        actionOutputs: createControlBoxIOInterface(),
      },
    ],
  };
}

export function createGenericWorkholding(
  kind: string,
  index: number,
): IntegrationActionGroup {
  return {
    name: `${kind} #${index + 1}`,
    actions: [
      {
        kind: 'clamp',
        id: uuid(),
        customActionName: '',
        actionOutputs: createControlBoxIOInterface(),
      },
      {
        kind: 'unclamp',
        id: uuid(),
        customActionName: '',
        actionOutputs: createControlBoxIOInterface(),
      },
    ],
  };
}

export function createHaasAutodoor(): IntegrationActionGroup {
  return {
    name: 'Autodoor',
    actions: [
      {
        kind: 'open',
        id: uuid(),
        customActionName: '',
        actionOutputs: { kind: 'haasNGC', program: '06005' },
      },
      {
        kind: 'close',
        id: uuid(),
        customActionName: '',
        actionOutputs: { kind: 'haasNGC', program: '06006' },
      },
    ],
  };
}

export function createHaasWorkholding(
  kind: string,
  index: number,
): IntegrationActionGroup {
  return {
    name: `${kind} #${index + 1}`,
    actions: [
      {
        kind: 'clamp',
        id: uuid(),
        customActionName: '',
        actionOutputs: {
          kind: 'haasNGC',
          program: ['06001', '06003'][index] ?? '',
        },
      },
      {
        kind: 'unclamp',
        id: uuid(),
        customActionName: '',
        actionOutputs: {
          kind: 'haasNGC',
          program: ['06002', '06004'][index] ?? '',
        },
      },
    ],
  };
}

export function createHaasMachineStatus(): MachineStatusConfig {
  return {
    running: { kind: 'haasNGC' },
    faulted: null,
  };
}
