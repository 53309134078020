import { capitalize, lowerCase, startCase } from 'lodash';

import type { RobotData } from '@sb/feathers-types';
import type { EquipmentItem } from '@sb/integrations/types';
import type { Robot } from '@sb/remote-control/types';
import type { OutputSafeguardRule } from '@sb/routine-runner';
import {
  INPUT_PORT_IDS,
  OUTPUT_PORT_IDS,
  SAFEGUARD_KIND_NAMES,
  OUPUT_SAFEGUARD_KIND_NAMES,
  SAFEGUARD_PAIR_PORTS,
  RELAY_PORT_IDS,
  type ExternalControlSettings,
  type SafeguardRule,
  FLANGE_INPUT_PORT_IDS,
  FLANGE_OUTPUT_PORT_IDS,
  OUTPUT_SAFEGUARD_PAIR_PORTS,
} from '@sb/routine-runner';

import { generateIOPort } from './generateIOPort';

function getEquipmentIOPorts(
  ports: Array<Robot.IOPort | null>,
  equipment: EquipmentItem[],
) {
  for (const { id, config } of equipment) {
    const addPort = (portID: string, portData: Partial<Robot.IOPort>) => {
      ports.push(
        generateIOPort(portID, {
          assignedTo: {
            kind: 'equipment',
            name: config.name,
            id,
          },
          ...portData,
        }),
      );
    };

    switch (config.kind) {
      case 'CustomIO': {
        for (const port of config.ports) {
          addPort(`${port.kind} ${port.port}`, port);
        }

        break;
      }

      case 'CNCMachine': {
        const addCNCPort = (portConfig: any, name: string) => {
          if (portConfig) {
            if (typeof portConfig.ioPort === 'string') {
              addPort(portConfig.ioPort, { name });
            } else if (Array.isArray(portConfig)) {
              for (const portConfig1 of portConfig) {
                addCNCPort(portConfig1, name);
              }
            }
          }
        };

        config.cycle?.actions.forEach((action) => {
          addCNCPort(
            action.actionOutputs.kind === 'controlBoxIO' &&
              action.actionOutputs.outputs,
            'Start cycle',
          );
        });

        addCNCPort(config.machineStatus.running, 'Running');
        addCNCPort(config.machineStatus.faulted, 'Faulted');

        config.autodoor.actions.forEach((action) => {
          addCNCPort(
            action.actionOutputs.kind === 'controlBoxIO' &&
              action.actionOutputs.outputs,
            `Autodoor (${action.kind === 'custom' ? action.customActionName : startCase(action.kind)})`,
          );
        });

        config.workholdings.forEach((workholding) => {
          workholding.actions.forEach((action) => {
            addCNCPort(
              action.actionOutputs.kind === 'controlBoxIO' &&
                action.actionOutputs.outputs,
              `${workholding.name} (${action.kind === 'custom' ? action.customActionName : startCase(action.kind)})`,
            );
          });
        });

        break;
      }

      default:
        break;
    }
  }
}

function getSafetyIOPorts(
  ports: Array<Robot.IOPort | null>,
  safeguardRules: SafeguardRule[],
  outputSafeguardRules: OutputSafeguardRule[],
) {
  for (const rule of safeguardRules) {
    if (rule.kind === 'none') {
      continue;
    }

    for (const port of SAFEGUARD_PAIR_PORTS[rule.pair]) {
      let highSignalName = 'High';
      let lowSignalName = 'Low';

      switch (rule.kind) {
        case 'estop': {
          highSignalName = 'Run';
          lowSignalName = 'Stop';
          break;
        }
        case 'slow': {
          highSignalName = 'Full Speed';
          lowSignalName = 'Slow Speed';
          break;
        }
        case 'pausehigh': {
          highSignalName = 'Pause';
          lowSignalName = 'Run';
          break;
        }
        case 'pauselow': {
          highSignalName = 'Run';
          lowSignalName = 'Pause';
          break;
        }
        case 'reset': {
          highSignalName = 'Reset';
          lowSignalName = 'None';
          break;
        }
        default:
          break;
      }

      ports.push(
        generateIOPort(`Input ${port}`, {
          name: SAFEGUARD_KIND_NAMES[rule.kind],
          assignedTo: {
            kind: 'safetyIO',
            name: 'Safety I/O',
          },
          highSignalName,
          lowSignalName,
        }),
      );
    }
  }

  for (const rule of outputSafeguardRules) {
    if (rule.kind === 'none') {
      continue;
    }

    for (const port of OUTPUT_SAFEGUARD_PAIR_PORTS[rule.pair]) {
      let highSignalName = 'High';
      let lowSignalName = 'Low';

      switch (rule.kind) {
        case 'isestop': {
          highSignalName = 'Estopped';
          lowSignalName = 'Not Estopped';
          break;
        }
        case 'isslow': {
          highSignalName = 'Slow Speed';
          lowSignalName = 'Not Slow Speed';
          break;
        }
        case 'isfullspeed': {
          highSignalName = 'Full Speed';
          lowSignalName = 'Not Full Speed';
          break;
        }
        case 'ismoving': {
          highSignalName = 'Moving';
          lowSignalName = 'Still';
          break;
        }
        case 'isstill': {
          highSignalName = 'Still';
          lowSignalName = 'Moving';
          break;
        }
        default:
          break;
      }

      ports.push(
        generateIOPort(`Output ${port}`, {
          name: OUPUT_SAFEGUARD_KIND_NAMES[rule.kind],
          assignedTo: {
            kind: 'safetyIO',
            name: 'Safety I/O',
          },
          highSignalName,
          lowSignalName,
        }),
      );
    }
  }

  return ports;
}

function getExternalControlIOPorts(
  ports: Array<Robot.IOPort | null>,
  externalControlSettings: ExternalControlSettings | undefined,
) {
  if (
    externalControlSettings &&
    externalControlSettings.isEnabled &&
    externalControlSettings.isRobotIOEnabled
  ) {
    for (const [key, portName] of Object.entries(
      externalControlSettings.ioPorts,
    )) {
      const name = capitalize(lowerCase(key));

      ports.push(
        generateIOPort(portName, {
          name,
          assignedTo: {
            kind: 'externalControl',
            name: 'External control',
          },
          highSignalName: name,
        }),
      );
    }
  }
}

function getRobotIOPorts(
  ports: Array<Robot.IOPort | null>,
  {
    ioInputs,
    ioOutputs,
    ioRelays,
    ioFlangeInputs,
    ioFlangeOutputs,
  }: Pick<
    RobotData,
    'ioInputs' | 'ioOutputs' | 'ioRelays' | 'ioFlangeInputs' | 'ioFlangeOutputs'
  >,
) {
  if (ioInputs) {
    for (const port of ioInputs) {
      ports.push(generateIOPort(`${port.kind} ${port.port}`, port));
    }
  }

  if (ioOutputs) {
    for (const port of ioOutputs) {
      ports.push(generateIOPort(`${port.kind} ${port.port}`, port));
    }
  }

  if (ioRelays) {
    for (const port of ioRelays) {
      ports.push(generateIOPort(`${port.kind} ${port.port}`, port));
    }
  }

  if (ioFlangeInputs) {
    for (const port of ioFlangeInputs) {
      ports.push(generateIOPort(`${port.kind} ${port.port}`, port));
    }
  }

  if (ioFlangeOutputs) {
    for (const port of ioFlangeOutputs) {
      ports.push(generateIOPort(`${port.kind} ${port.port}`, port));
    }
  }

  return ports;
}

export function getIOPorts(
  robotIO: Pick<
    RobotData,
    'ioInputs' | 'ioOutputs' | 'ioRelays' | 'ioFlangeInputs' | 'ioFlangeOutputs'
  >,
  safeguardRules: SafeguardRule[],
  outputSafeguardRules: OutputSafeguardRule[],
  externalControlSettings: ExternalControlSettings | undefined,
  equipment: EquipmentItem[],
): {
  ioInputs: Robot.InputPort[];
  ioOutputs: Robot.OutputPort[];
  ioRelays: Robot.RelayPort[];
  ioFlangeInputs: Robot.FlangeInputPort[];
  ioFlangeOutputs: Robot.FlangeOutputPort[];
} {
  const allPorts: Array<Robot.IOPort | null> = [];

  // ports assigned in safety settings
  getSafetyIOPorts(allPorts, safeguardRules, outputSafeguardRules);
  // ports assigned in external control
  getExternalControlIOPorts(allPorts, externalControlSettings);
  // ports assigned in equipment manager
  getEquipmentIOPorts(allPorts, equipment);
  // ports defined in IO Manager
  getRobotIOPorts(allPorts, robotIO);

  const ioInputs = INPUT_PORT_IDS.map((portID) => {
    return (
      allPorts.find((p): p is Robot.InputPort => p?.portID === portID) ??
      generateIOPort(portID)
    );
  });

  const ioOutputs = OUTPUT_PORT_IDS.map((portID) => {
    return (
      allPorts.find((p): p is Robot.OutputPort => p?.portID === portID) ??
      generateIOPort(portID)
    );
  });

  const ioRelays = RELAY_PORT_IDS.map((portID) => {
    return (
      allPorts.find((p): p is Robot.RelayPort => p?.portID === portID) ??
      generateIOPort(portID)
    );
  });

  const ioFlangeInputs = FLANGE_INPUT_PORT_IDS.map((portID) => {
    return (
      allPorts.find((p): p is Robot.FlangeInputPort => p?.portID === portID) ??
      generateIOPort(portID)
    );
  });

  const ioFlangeOutputs = FLANGE_OUTPUT_PORT_IDS.map((portID) => {
    return (
      allPorts.find((p): p is Robot.FlangeOutputPort => p?.portID === portID) ??
      generateIOPort(portID)
    );
  });

  return { ioInputs, ioOutputs, ioRelays, ioFlangeInputs, ioFlangeOutputs };
}
