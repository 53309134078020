import * as zod from 'zod';

import {
  IntegrationActionControlBoxIOInterface,
  IntegrationActionControlBoxIOOutputs,
} from '@sb/integrations/io/types/IntegrationActionControlBoxIOInterface';
import {
  IntegrationActionFlangeIOInterface,
  IntegrationActionFlangeIOOutputs,
} from '@sb/integrations/io/types/IntegrationActionFlangeIOInterface';
import { IntegrationActionHaasNGCInterface } from '@sb/integrations/io/types/IntegrationActionHaasNGCInterface';
import { IntegrationActionKind } from '@sb/integrations/io/types/IntegrationActionKind';
import { IntegrationActionModbusTCPInterface } from '@sb/integrations/io/types/IntegrationActionModbusTCPInterface';
import { IntegrationControlBoxIOInterface } from '@sb/integrations/io/types/IntegrationControlBoxIOInterface';
import { IntegrationFlangeIOInterface } from '@sb/integrations/io/types/IntegrationFlangeIOInterface';
import { IntegrationModbusTCPInterface } from '@sb/integrations/io/types/IntegrationModbusTCPInterface';
import { IntegrationSensorControlBoxIOInterface } from '@sb/integrations/io/types/IntegrationSensorControlBoxIOInterface';
import { IntegrationSensorFlangeIOInterface } from '@sb/integrations/io/types/IntegrationSensorFlangeIOInterface';

export const CustomEquipmentControlInterfaceConfiguration = zod.union([
  IntegrationControlBoxIOInterface,
  IntegrationFlangeIOInterface,
  IntegrationModbusTCPInterface,
]);

export type CustomEquipmentControlInterfaceConfiguration = zod.infer<
  typeof CustomEquipmentControlInterfaceConfiguration
>;

export const CustomEquipmentIntegrationActionOutputs = zod.union([
  IntegrationActionFlangeIOOutputs,
  IntegrationActionControlBoxIOOutputs,
]);

export type CustomEquipmentIntegrationActionOutputs = zod.infer<
  typeof CustomEquipmentIntegrationActionOutputs
>;

export const CustomEquipmentActionConfigurationActionOutputs =
  zod.discriminatedUnion('kind', [
    IntegrationActionControlBoxIOInterface,
    IntegrationActionFlangeIOInterface,
    IntegrationActionModbusTCPInterface,
    IntegrationActionHaasNGCInterface,
  ]);

export type CustomEquipmentActionConfigurationActionOutputs = zod.infer<
  typeof CustomEquipmentActionConfigurationActionOutputs
>;

export const CustomEquipmentActionConfigurationActionOutputsIOOnly =
  zod.discriminatedUnion('kind', [
    IntegrationActionControlBoxIOInterface,
    IntegrationActionFlangeIOInterface,
  ]);

export type CustomEquipmentActionConfigurationActionOutputsIOOnly = zod.infer<
  typeof CustomEquipmentActionConfigurationActionOutputsIOOnly
>;

export const CustomEquipmentActionConfigurationActionOutputsModbusTCPOnly =
  zod.discriminatedUnion('kind', [IntegrationActionModbusTCPInterface]);

export type CustomEquipmentActionConfigurationActionOutputsModbusTCPOnly =
  zod.infer<
    typeof CustomEquipmentActionConfigurationActionOutputsModbusTCPOnly
  >;

export const CustomEquipmentActionConfiguration = zod.object({
  id: zod.string(),
  kind: IntegrationActionKind,
  customActionName: zod.string().default(''),
  actionOutputs: CustomEquipmentActionConfigurationActionOutputs,
});

export type CustomEquipmentActionConfiguration = zod.infer<
  typeof CustomEquipmentActionConfiguration
>;

export const CustomEquipmentActionConfigurationIO =
  CustomEquipmentActionConfiguration.extend({
    actionOutputs: CustomEquipmentActionConfigurationActionOutputsIOOnly,
  });

export type CustomEquipmentActionConfigurationIO = zod.infer<
  typeof CustomEquipmentActionConfigurationIO
>;

export const CustomEquipmentActionConfigurationModbusTCP =
  CustomEquipmentActionConfiguration.extend({
    actionOutputs: CustomEquipmentActionConfigurationActionOutputsModbusTCPOnly,
  });

export type CustomEquipmentActionConfigurationModbusTCP = zod.infer<
  typeof CustomEquipmentActionConfigurationModbusTCP
>;

// Generic Sensor Configuration
export const CustomEquipmentSensorConfiguration = zod.object({
  id: zod.string(),
  interface: zod
    .union([
      IntegrationSensorControlBoxIOInterface,
      IntegrationSensorFlangeIOInterface,
    ])
    .nullable()
    .default(null),
});

export type CustomEquipmentSensorConfiguration = zod.infer<
  typeof CustomEquipmentSensorConfiguration
>;

// Generic Equipment Configuration
export const CustomEquipmentConfiguration = zod.object({
  kind: zod.string(),
  name: zod.string(),
  interfaces: zod
    .array(CustomEquipmentControlInterfaceConfiguration)
    .default([]),
  sensors: zod.array(CustomEquipmentSensorConfiguration).default([]),
  actions: zod.array(CustomEquipmentActionConfiguration).default([]),
});

export type CustomEquipmentConfiguration = zod.infer<
  typeof CustomEquipmentConfiguration
>;
